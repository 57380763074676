<template>
  <!-- 投资者关系日历 -->
  <div :id="this.$store.state.nowlanguage === 'en' ? 'ver-en' : ''">
    <div class="banner" id="investor_banner1" :style="{backgroundImage:('url('+banner+')')}">
      <!-- <img :src="banner" /> -->
      <span
        >{{ $t("indexnav.IR Calendar")
        }}<i
          :style="{
            display: this.$store.state.nowlanguage === 'en' ? 'none' : '',
          }"
          >IR Calendar</i
        ></span
      >
    </div>
    <!-- 跳转 -->
    <section class="ab-item1">
      <!-- <a href="/">{{ $t("indexnav.Home") }}</a> >
      <a href="/ir/default.html">{{ $t("indexnav.Investor Relations") }}</a> >
      {{ $t("indexnav.IR Calendar") }} -->
      <bread-crumb />
    </section>
    <!-- 选择年份 -->
    <section class="content"  v-if="false">
      <div>
        <!-- 当前年份 -->
        <div>{{ nowYear }}</div>
        <!-- 下拉框 -->
        <ul>
          <li @click="checkyear">
            <span>{{ $t("responsibility.Select Year") }}</span>
          </li>
          <ul
            :style="{
              height: showyear ? 47 * 3 + 'px' : '0px',
              transition: 'all,0.4s',
            }"
          >
            <li
              v-for="year in yearList"
              :key="year + 'key'"
              @click="ClickYear(year)"
            >
              <i>{{ year }}</i>
            </li>
          </ul>
        </ul>
      </div>
      <div class="select-content"></div>
    </section>
    <div id="iframeContainer"></div>
    <!-- 内容 -->
    <!-- <section>
      <div class="loading_box" v-if="false"></div>
      <div class="select-content">
        <div class="calendar_list">
          <dl>
            <dd v-for="item in dataList" :key="item.id">
              <span
                ><strong>{{ $t("touzizheguanxirili.日期") }}:</strong>
                {{ item.date }}</span
              >
                <span v-if="item.beginTime != ''"
                ><strong>{{ $t("touzizheguanxirili.时间") }}:</strong>
                {{ item.beginTime }} - {{ item.endTime }}</span
                >
              <span
                ><strong>{{ $t("touzizheguanxirili.活动") }}:</strong>
                {{ filterLang(item, "Activity") }}</span
              >
              <span
                ><strong>{{ $t("touzizheguanxirili.地点") }}:</strong>
                {{ filterLang(item, "Address") }}</span
              >
                <span v-if="(filterLang(item, 'Org'))"
                ><strong>{{ $t("touzizheguanxirili.机构") }}:</strong>
                {{ filterLang(item, "Org") }}</span
                >
            </dd>
          </dl>
        </div>
      </div>
    </section> -->
  </div>
</template>

<script>
import moment from "dayjs";
import { TouZiZheRiLi } from "@/api/investors";
import { getyearList, Clickyear } from "@/utils/clickyear";
import { filterLang } from "@/utils/LangChange";
import { PageType } from "@/utils/constant";
import { getAction } from "@/api/manage";
import { ifarmeMixin } from "../mixin/ifarmeMixin";

export default {
  mixins: [
    ifarmeMixin
  ],
  data() {
    return {
      description: "社会责任列表页面",
      showLoading: true,
      showyear: false,
      resList: [],
      dataList: [],
      yearList: [],
      banner: "", 
      izh: "https://jinxinfertility.website.wisdomir.com/sc/ir_calendar.php",
      itr: "https://jinxinfertility.website.wisdomir.com/tc/ir_calendar.php",
      ien: "https://jinxinfertility.website.wisdomir.com/en/ir_calendar.php",
    };
  },
  methods: {
    checkyear() {
      this.showyear = !this.showyear;
    },
    filterLang,
    // 点击年份切换数据
    ClickYear(year) {
      this.showyear = !this.showyear;
      this.nowYear = year;
      this.dataList = Clickyear(this.resList, year);
    },
  },
  created() {
    // 初始化banner
    let that = this;
    getAction("/jeecg-system/common/frontend/advertise/active_list", {
      typeCode: PageType.TOUZIZHEGUANXIRILI,
    }).then((res) => {
      if (
        res.result.records instanceof Array &&
        res.result.records.length > 0
      ) {
         if (res.result.records[0].isMultipatLanguge!=='0') {
          if (this.$store.state.nowlanguage == "zh")
            that.banner = res.result.records[0].sampleImg;
          if (this.$store.state.nowlanguage == "tr")
            that.banner = res.result.records[0].tranditionalImg;
          if (this.$store.state.nowlanguage == "en")
            that.banner = res.result.records[0].enImg;
        }else{
        that.banner = res.result.records[0].sampleImg
        }
      }
    });

    TouZiZheRiLi().then((res) => {
      this.showLoading = false;
      this.resList = res.result;

      //年份列表
      this.yearList = getyearList(this.resList);

      // 设置当前年份，默认取 数据 中，最大的年份，作为初始年份
      if (this.yearList != null && this.yearList.length > 0) {
        this.nowYear = this.yearList[0];
      } else {
        // 获取当前年
        this.nowYear = moment(new Date()).year();
      }

      // 默认2020年数据
      this.dataList = Clickyear(this.resList, this.nowYear);
    });
  },
  mounted() {
    if (this.$store.state.nowlanguage == "zh") {
      this.setIframe(this.izh);
    }
    if (this.$store.state.nowlanguage == "tr") {
      this.setIframe(this.itr);
    }
    if (this.$store.state.nowlanguage == "en") {
      this.setIframe(this.ien);
    }
  },
};
</script>

<style scoped lang=less>
header + a img {
  max-width: 100%;
  width: 100%;
}
.banner {
  position: relative;
}
.banner img {
  width: 100%;
  max-width: 100vw;
  display: block;
}

section,
article {
  margin: 1.5em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

.ab-item1 {
  padding: 0 0 1.5em 0;
  border-bottom: 1px solid #d2d2d2;
  font-size: 1.5rem;
  color: #595757;
}

article div:nth-child(1) {
  display: flex;
  align-items: flex-start;
  position: relative;
}

article div:nth-child(1) div {
  background: #165da3;
  padding: 0.3em 0.9em;
}

article div:nth-child(1) div ul {
  display: none;
  z-index: 99;
}

article div:nth-child(1) div ul li:last-child {
  padding-bottom: 0.6em;
}

article div:nth-child(1) div ul li a {
  display: block;
  color: #fff;
  text-align: center;
  line-height: 1.6em;
  padding: 0.3em 0;
}

article div:nth-child(1) div div a {
  color: #fff;
  font-size: 1em;
  display: block;
}

article div:nth-child(1) div:hover ul {
  display: block;
  position: absolute;
  margin: 2em 0 0 0;
  background: #165da3;
  width: 100%;
  left: 0;
}

article div:nth-child(2) > div dl {
  margin: 0 0 20px 0;
}

article div:nth-child(2) > div dl dt {
  height: 30px;
}

article div:nth-child(2) > div dl dd {
  border-bottom: 1px solid #dddddd;
  overflow: hidden;
  padding: 20px 0;
}

article div:nth-child(2) > div dl dd a {
  line-height: 17px;
  color: #8f8f8f;
  float: left;
}

article div:nth-child(2) > div dl dd span {
  color: #666666;
  padding: 0 0 5px 0;
  display: block;
}

article div:nth-child(2) > div dl dd span strong {
  color: #18325f;
  float: left;
  width: 60px;
}

#ver-en .calendar_list dl dd span strong {
  width: 100px;
}

#iframeContainer {
  margin: 3em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

iframe {
  margin: 0.6em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

@media screen and (max-width: 1200px) {
  .ab-item1 {
    font-size: 1.2em;
    padding: 0 1.5em 1.5em 1.5em;
  }

  article {
    padding: 0 1.5em 1.5em 1.5em;
  }
}
/* 内容 */
.calendar_list dl {
  margin: 0 30px 20px 30px;
  dd {
    border-bottom: 1px solid #dddddd;
    overflow: hidden;
    padding: 20px 0px;
  }
  span {
    color: #666666;
    padding: 0px 0px 5px 0;
    display: block;
    font: 14px/2 "宋体", Arial;
    color: #666666;
  }
  strong {
    color: #18325f;
    float: left;
    width: 60px;
  }
}

/* 选择年份 */
.content {
  > div:first-child {
    > div {
      display: block;
      float: left;
      color: #18325f;
      font-size: 1.3rem;
    }
    display: flex;
    justify-content: space-between;
    margin: 0 30px;
    border-bottom: 5px solid #18325f;
    > ul {
      position: relative;
      > li {
        color: #fff;
        cursor: pointer;
        background: #18325f;
        padding: 10px 40px 10px 15px;
        position: relative;
        top: 1px;
        > span {
          font-size: 14px;
        }
      }
      > ul {
        width: 100%;
        position: absolute;
        left: 0;
        text-align: center;
        border-radius: 0px !important;
        overflow: hidden;
        > li {
          font-size: 14px;
          padding: 14px 0;
          color: #fff;
          cursor: pointer;
          background: #18325f;
        }
        > li:hover {
          background: #747474 !important;
        }
      }
    }
  }
}
/* 三角 */
.content div > ul > li > span:after {
  content: "";
  width: 0px;
  height: 0px;
  display: block;
  position: absolute;
  top: 17px;
  right: 11px;
  z-index: 11;
  border-top: 8px solid #fff;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}
.content div:first-child > div {
  color: #18325f;
  font-size: 1.3rem !important;
  font: 14px/2 "宋体", Arial;
}
</style>
